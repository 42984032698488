<script>
	import GlobalVue from '@helper/Global.vue';
	export default {
		extends: GlobalVue,
		data() {
			return {}
		},
		async mounted() {
		},
		methods: {}
	};
</script>
<template>
	<svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 100 100' width='100px'>
    <title>dimatique</title>
    <circle class='cls-1' cx='50' cy='14.26' r='9.78' />
    <circle class='cls-1' cx='31.44' cy='50' r='9.78' />
    <circle class='cls-1' cx='12.87' cy='85.74' r='9.78' />
    <circle class='cls-1' cx='50' cy='85.74' r='9.78' />
    <circle class='cls-1' cx='87.13' cy='85.74' r='9.78' />
    <circle class='cls-1' cx='68.56' cy='50' r='9.78' />
	</svg>
</template>